import * as React from "react";

const Logo = ({ className }) => {
  return (
    <div className={`logo ${className}`}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 117 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="58.5" cy="58.5" r="58.5" fill="#FF4522" />
        <g>
          <path
            d="M56.1669 40.1497C56.1669 40.1497 53.9742 38.8893 55.7071 36.5887C57.4401 34.2882 59.7389 32.7986 57.3163 30.9564C54.8937 29.1143 52.4799 31.071 51.4366 33.6007C50.3933 36.1304 50.8619 38.6513 47.2899 37.8492C43.7179 37.0471 39.801 38.4221 36.8037 43.5961C33.8064 48.7612 31.5076 48.9904 30.0045 50.3742C28.5103 51.7492 24.4697 56.9232 27.9267 62.3175C29.6066 64.9353 32.2238 66.0107 34.4872 66.4337C36.3528 66.7775 38.2714 66.6893 40.1105 66.231C42.7011 65.5876 48.4658 64.5122 48.5543 67.7206C48.6692 71.8545 44.9822 73.6967 40.0309 76.9139C35.0796 80.1222 30.818 83.9124 31.1628 88.1696C31.5076 92.4269 35.3095 96.552 40.6145 96.3228C45.9106 96.0936 49.7213 91.5014 50.1811 87.4821C50.6409 83.4628 46.839 84.9524 45.3447 86.7946C43.8505 88.6368 38.7754 90.9285 38.5455 86.4509C38.3156 81.9732 45.5746 79.7873 49.0317 77.7248C52.4888 75.6534 58.0148 70.7175 52.6037 61.295C52.6037 61.295 50.296 56.4737 52.3738 53.0273C54.4516 49.5809 58.2535 47.2804 58.3685 44.5304C58.4834 41.7803 56.1669 40.1497 56.1669 40.1497ZM49.6241 47.8445C49.2793 49.9952 47.3783 51.1146 45.9106 52.5866C44.4429 54.0497 42.5419 56.7205 41.5871 57.8399C40.6322 58.9593 38.2184 59.9905 36.9187 58.7918C35.619 57.5842 35.451 54.0497 39.6861 49.484C43.9212 44.9182 46.5118 45.262 46.5118 45.262C47.8116 44.9182 49.9689 45.6938 49.6241 47.8445Z"
            fill="#99018A"
          />
          <path
            d="M85.8042 64.4771C85.1588 63.9042 84.3895 63.437 83.8767 62.7407C82.9572 61.5155 82.9837 59.8496 82.9572 58.3247C82.9218 56.5707 82.7361 54.7726 81.9139 53.2301C81.0916 51.6876 79.5001 50.436 77.7495 50.4713C76.0519 50.5065 74.6107 51.67 73.3198 52.7718C71.7283 54.1292 70.1368 55.4954 68.5453 56.8528C67.9795 57.3376 67.3959 57.8223 66.6886 58.0427C65.9813 58.2631 65.1059 58.1308 64.6727 57.5315C64.2218 56.9233 64.3544 56.0859 64.5401 55.3544C65.6011 51.2822 67.9176 47.6507 69.3765 43.7019C71.8698 36.9679 71.6045 28.894 67.2898 23.1472C66.5295 22.1335 65.6188 21.1728 64.4605 20.6615C63.3023 20.1503 61.8345 20.1503 60.8443 20.9436C59.854 21.7369 59.5711 23.3851 60.4553 24.3018C60.8443 24.7073 61.3836 24.9188 61.8611 25.2097C63.9212 26.4966 64.5755 29.1673 64.7258 31.5824C65.1767 38.8189 62.5772 46.1876 57.6967 51.5554C56.9274 52.4016 56.0875 53.2301 55.6366 54.279C54.6994 56.4826 55.7338 58.9946 56.9274 61.066C58.1211 63.1373 59.5357 65.2968 59.3854 67.6855C59.244 69.9595 57.6701 71.9692 57.5729 74.2521C57.4933 76.0678 58.3863 77.813 59.633 79.144C59.7656 79.285 59.8982 79.4348 60.0309 79.5759C60.7205 80.3691 63.4702 83.1368 67.8203 82.4669C69.1466 82.2642 70.3313 81.3211 71.6753 81.3828C73.0015 81.4357 74.1775 82.2025 75.433 82.6608C78.4745 83.7714 82.0553 82.9253 84.5133 80.8275C86.9713 78.7297 88.3594 75.5301 88.5628 72.3129C88.7396 69.4483 87.9527 66.381 85.8042 64.4771ZM68.8902 66.0901C69.4383 64.8825 70.1722 63.7543 71.0298 62.7407C71.6664 61.9827 72.3296 61.2423 73.196 60.7839C74.3808 60.1581 76.1403 60.3168 76.7327 61.5155C77.3428 62.7407 76.4409 64.1598 76.3171 65.526C76.2199 66.6278 76.6354 67.7119 76.6973 68.8137C76.7769 70.365 76.1049 71.9339 74.929 72.9476C73.7531 73.9612 72.0908 74.3931 70.5701 74.0846C69.3853 73.8466 68.2536 72.9828 68.1033 71.7841C67.8999 70.1623 68.2536 68.2584 68.4746 67.3064C68.5719 66.901 68.6956 66.5044 68.8725 66.1253C68.8725 66.1165 68.8813 66.0989 68.8902 66.0901Z"
            fill="#99018A"
          />
        </g>
      </svg>
    </div>
  );
};

export default Logo;
