import React, { useEffect, useState } from "react";
import { CountUp } from "countup.js";

import classnames from "classnames";

import "./index.scss";

const Loader = ({ isOpened = false }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    const countUp = new CountUp("value", 100, {
      formattingFn: (v) => {
        setValue(v);
        return `${v}%`;
      },
    });
    setTimeout(() => {
      countUp.start();
    }, 500);
  }, []);
  return (
    <div
      className={classnames("loader", {
        ["-opened"]: isOpened,
      })}
    >
      <div className="figure">
        <div
          className="layer"
          style={{ transform: `scaleX(${value / 100})` }}
        />
        <div className="value" id="value"></div>
        <div className="svg-wrapper">
          <svg
            width="425"
            height="425"
            viewBox="0 0 425 425"
            fill="none"
            className="illustration"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* <circle
            cx="212.5"
            cy="212.5"
            r="206.5"
            // fill="#FF4522"
            // stroke="#D604C1"
            // strokeWidth="12"
          /> */}
            <g className="letters">
              <path d="M380.737 222.29C380.871 222.201 381.048 222.157 381.226 222.157L400.35 224.024C400.528 224.024 400.706 224.113 400.795 224.246C400.884 224.379 400.973 224.557 400.928 224.735L400.795 226.202C400.795 226.38 400.706 226.557 400.572 226.646C400.439 226.735 400.261 226.78 400.083 226.78L382.738 225.091L381.804 234.825C381.804 235.047 381.716 235.225 381.582 235.314C381.449 235.447 381.271 235.492 381.093 235.447L379.981 235.358C379.803 235.358 379.625 235.27 379.536 235.092C379.403 234.958 379.358 234.781 379.403 234.558L380.559 222.69C380.559 222.557 380.604 222.379 380.737 222.29Z" />
              <path d="M380.203 238.781C381.626 237.848 383.316 237.581 385.317 237.892L386.563 238.114L387.808 238.381C389.765 238.825 391.232 239.759 392.211 241.181C393.189 242.559 393.456 244.293 393.056 246.382C392.655 248.471 391.766 249.938 390.343 250.871C388.92 251.805 387.23 252.116 385.228 251.805C384.784 251.76 384.383 251.716 383.983 251.627C383.583 251.538 383.183 251.449 382.738 251.316C380.781 250.871 379.313 249.938 378.335 248.56C377.357 247.182 377.09 245.448 377.49 243.315C377.89 241.181 378.78 239.67 380.203 238.781ZM380.247 246.871C380.87 247.76 381.937 248.338 383.36 248.693C383.538 248.738 383.894 248.827 384.428 248.916C384.962 249.004 385.317 249.093 385.54 249.093C387.007 249.316 388.208 249.138 389.142 248.56C390.076 247.982 390.654 247.093 390.876 245.849C391.099 244.604 390.921 243.582 390.298 242.693C389.676 241.804 388.608 241.226 387.185 240.87L386.118 240.648L385.006 240.47C383.538 240.248 382.338 240.426 381.404 241.004C380.47 241.581 379.892 242.47 379.669 243.715C379.402 244.96 379.58 246.026 380.247 246.871Z" />
              <path d="M375.489 254.872C376.112 254.205 376.779 253.716 377.579 253.449C378.38 253.183 379.136 253.183 379.936 253.405C381.182 253.761 382.027 254.561 382.516 255.805C383.005 257.05 383.138 258.606 382.872 260.428L382.205 264.829L383.005 265.051C384.917 265.629 386.252 264.784 386.874 262.562C387.141 261.717 387.141 260.961 386.963 260.339C386.785 259.717 386.474 259.228 386.074 258.828C385.896 258.694 385.807 258.561 385.762 258.472C385.718 258.383 385.718 258.25 385.762 258.072L386.074 256.961C386.118 256.783 386.207 256.65 386.385 256.561C386.563 256.472 386.696 256.472 386.874 256.516C387.319 256.65 387.764 257.005 388.208 257.583C388.653 258.161 388.965 258.917 389.142 259.895C389.32 260.873 389.231 261.939 388.876 263.184C388.253 265.273 387.319 266.651 386.074 267.273C384.828 267.896 383.494 267.984 382.027 267.54L372.865 264.829C372.687 264.784 372.554 264.651 372.465 264.517C372.376 264.34 372.376 264.206 372.42 263.984L372.776 262.739C372.821 262.562 372.954 262.428 373.087 262.339C373.221 262.25 373.399 262.25 373.621 262.295L374.866 262.65C374.377 262.073 374.021 261.317 373.843 260.384C373.666 259.495 373.755 258.383 374.11 257.183C374.422 256.339 374.866 255.539 375.489 254.872ZM376.334 261.806C376.912 262.828 377.98 263.584 379.492 264.028L380.292 264.251L380.781 260.828C381.004 259.406 380.959 258.294 380.737 257.494C380.47 256.65 380.025 256.161 379.358 255.939C378.602 255.716 377.891 255.85 377.312 256.339C376.734 256.828 376.29 257.539 376.023 258.383C375.667 259.628 375.8 260.784 376.334 261.806Z" />
              <path d="M372.287 269.762C373.888 269.318 375.667 269.407 377.579 270.118L378.424 270.429L379.225 270.785C381.048 271.629 382.427 272.741 383.272 274.207C384.117 275.674 384.161 277.363 383.405 279.23C382.649 281.097 381.359 282.297 379.625 282.831L385.896 285.364C386.074 285.453 386.207 285.542 386.251 285.72C386.34 285.898 386.34 286.075 386.251 286.253L385.762 287.498C385.673 287.676 385.584 287.809 385.407 287.853C385.229 287.942 385.051 287.942 384.873 287.853L366.817 280.564C366.639 280.475 366.505 280.386 366.461 280.208C366.372 280.03 366.372 279.852 366.461 279.675L366.95 278.519C367.039 278.341 367.128 278.208 367.306 278.163C367.484 278.119 367.662 278.119 367.839 278.163L368.996 278.652C368.017 277.052 367.973 275.274 368.729 273.318C369.44 271.407 370.641 270.207 372.287 269.762ZM370.686 278.03C371.264 279.008 372.153 279.764 373.265 280.297C373.443 280.386 373.799 280.564 374.288 280.742C374.777 280.964 375.133 281.097 375.355 281.142C376.467 281.542 377.579 281.586 378.647 281.275C379.714 280.919 380.514 280.164 381.004 278.919C381.537 277.63 381.493 276.519 380.915 275.585C380.337 274.652 379.403 273.896 378.113 273.318L377.357 272.963C373.888 271.54 371.62 272.118 370.552 274.696C370.063 275.941 370.108 277.052 370.686 278.03Z" />
              <path d="M364.415 284.209C364.593 284.164 364.771 284.164 364.949 284.253L377.446 290.387C377.624 290.476 377.757 290.609 377.801 290.787C377.846 290.965 377.846 291.143 377.757 291.321L377.179 292.476C377.09 292.654 376.956 292.787 376.779 292.832C376.601 292.876 376.423 292.876 376.245 292.787L363.748 286.653C363.57 286.564 363.436 286.431 363.392 286.253C363.348 286.075 363.348 285.898 363.436 285.72L364.015 284.564C364.148 284.342 364.237 284.253 364.415 284.209ZM380.826 291.943C381.004 291.898 381.181 291.898 381.359 291.987L382.738 292.654C382.916 292.743 383.049 292.876 383.094 293.054C383.183 293.232 383.138 293.41 383.049 293.587L382.293 295.143C382.204 295.321 382.071 295.454 381.893 295.499C381.715 295.543 381.537 295.543 381.359 295.454L379.981 294.788C379.803 294.699 379.669 294.565 379.625 294.388C379.58 294.21 379.58 294.032 379.669 293.854L380.425 292.298C380.514 292.121 380.648 291.987 380.826 291.943Z" />
              <path d="M361.524 290.209C361.702 290.165 361.88 290.209 362.058 290.298L374.066 297.277C374.244 297.366 374.333 297.499 374.377 297.677C374.422 297.855 374.377 298.032 374.288 298.21L373.666 299.321C373.577 299.499 373.443 299.633 373.265 299.677C373.087 299.722 372.91 299.677 372.732 299.588L371.62 298.921C371.931 299.81 372.109 300.655 372.02 301.544C371.976 302.388 371.664 303.322 371.086 304.344C370.152 305.989 368.907 306.922 367.306 307.234C365.749 307.545 364.104 307.189 362.414 306.167L355.254 301.988C355.076 301.9 354.987 301.766 354.942 301.588C354.898 301.411 354.942 301.233 355.031 301.055L355.698 299.899C355.787 299.722 355.921 299.633 356.099 299.588C356.276 299.544 356.454 299.588 356.632 299.677L363.659 303.766C364.86 304.478 366.016 304.744 367.039 304.522C368.062 304.344 368.907 303.678 369.53 302.566C370.152 301.499 370.33 300.433 370.019 299.366C369.707 298.344 368.951 297.455 367.751 296.743L360.724 292.654C360.546 292.565 360.457 292.432 360.412 292.254C360.368 292.076 360.412 291.898 360.501 291.721L361.169 290.565C361.213 290.387 361.346 290.254 361.524 290.209Z" />
              <path d="M346.67 303.278C347.693 302.789 348.582 302.611 349.427 302.7C350.228 302.789 350.851 302.966 351.251 303.278C351.384 303.366 351.473 303.544 351.518 303.722C351.562 303.9 351.518 304.078 351.384 304.211L350.628 305.278C350.539 305.411 350.406 305.5 350.272 305.544C350.139 305.589 349.961 305.589 349.694 305.5C348.093 305.011 346.759 305.589 345.603 307.145C344.758 308.345 344.357 309.412 344.446 310.345C344.535 311.278 345.247 312.212 346.492 313.145L348.138 314.345C347.648 312.567 347.96 310.878 349.161 309.234C350.361 307.545 351.829 306.7 353.519 306.656C355.209 306.611 356.899 307.145 358.545 308.3L359.212 308.745L359.834 309.234C361.435 310.478 362.458 311.901 362.947 313.501C363.437 315.101 363.081 316.746 361.836 318.435C360.635 320.079 359.078 320.968 357.166 321.013L358.189 321.769C358.367 321.902 358.456 322.035 358.5 322.213C358.545 322.391 358.5 322.569 358.367 322.746L357.611 323.769C357.477 323.947 357.344 324.035 357.166 324.035C356.988 324.035 356.81 323.991 356.632 323.902L345.113 315.546C341.467 312.879 340.977 309.723 343.646 306.033C344.669 304.655 345.692 303.766 346.67 303.278ZM350.006 314.257C350.317 315.323 350.984 316.257 351.962 317.057C352.096 317.19 352.363 317.368 352.763 317.679C353.163 317.946 353.43 318.168 353.608 318.257C354.675 318.924 355.743 319.279 356.855 319.235C357.966 319.19 358.945 318.613 359.745 317.501C360.59 316.346 360.813 315.279 360.457 314.257C360.101 313.234 359.39 312.256 358.278 311.367L357.7 310.878L357.032 310.434C355.876 309.634 354.72 309.278 353.608 309.234C352.496 309.234 351.562 309.812 350.717 310.923C349.917 312.079 349.694 313.19 350.006 314.257Z" />
              <path d="M341.956 329.236C342.801 329.192 343.557 329.458 344.179 330.081C344.802 330.659 345.158 331.414 345.158 332.259C345.202 333.148 344.891 333.859 344.313 334.526C343.735 335.148 342.979 335.459 342.134 335.504C341.289 335.504 340.533 335.237 339.91 334.659C339.287 334.081 338.976 333.37 338.932 332.481C338.887 331.636 339.154 330.881 339.776 330.258C340.355 329.592 341.066 329.236 341.956 329.236Z" />
              <path d="M324.923 336.837C325.1 336.837 325.234 336.926 325.367 337.104L337.731 351.772C337.864 351.905 337.909 352.083 337.909 352.261C337.909 352.439 337.82 352.572 337.686 352.706L336.575 353.639C336.441 353.772 336.263 353.817 336.085 353.817C335.907 353.817 335.774 353.728 335.641 353.55L324.433 340.215L316.917 346.527C316.739 346.66 316.561 346.705 316.384 346.705C316.206 346.705 316.072 346.616 315.939 346.438L315.227 345.594C315.094 345.46 315.049 345.282 315.094 345.105C315.138 344.927 315.183 344.749 315.361 344.66L324.478 337.015C324.611 336.881 324.745 336.837 324.923 336.837Z" />
              <path d="M313.003 348.261C314.649 348.616 316.072 349.55 317.273 351.194L318.029 352.217L318.74 353.283C319.808 354.972 320.208 356.662 319.941 358.351C319.674 360.04 318.652 361.462 316.917 362.662C315.183 363.862 313.493 364.307 311.803 363.996C310.157 363.685 308.689 362.707 307.489 361.107C307.222 360.795 306.955 360.44 306.733 360.084C306.51 359.729 306.288 359.373 306.066 359.017C304.998 357.328 304.553 355.639 304.82 353.995C305.087 352.35 306.11 350.883 307.889 349.683C309.668 348.349 311.358 347.905 313.003 348.261ZM307.355 354.039C307.222 355.106 307.533 356.262 308.334 357.506C308.423 357.684 308.645 357.995 308.956 358.44C309.268 358.884 309.49 359.195 309.623 359.329C310.513 360.529 311.491 361.24 312.559 361.462C313.626 361.684 314.649 361.462 315.672 360.751C316.695 360.04 317.273 359.151 317.451 358.084C317.629 357.017 317.273 355.861 316.472 354.617L315.85 353.683L315.183 352.794C314.293 351.594 313.315 350.883 312.247 350.661C311.18 350.439 310.157 350.661 309.134 351.372C308.111 352.083 307.489 352.972 307.355 354.039Z" />
              <path d="M298.372 356.439C299.261 356.395 300.106 356.528 300.862 356.884C301.618 357.239 302.196 357.773 302.597 358.484C303.219 359.595 303.264 360.795 302.775 362.04C302.241 363.285 301.262 364.44 299.795 365.596L296.237 368.263L296.637 369.019C297.616 370.797 299.128 371.108 301.173 369.952C301.974 369.507 302.508 369.019 302.819 368.396C303.13 367.818 303.264 367.241 303.264 366.707C303.219 366.485 303.264 366.352 303.308 366.218C303.353 366.085 303.442 366.04 303.575 365.952L304.554 365.374C304.687 365.285 304.865 365.285 305.043 365.329C305.221 365.374 305.354 365.463 305.443 365.64C305.665 366.04 305.754 366.574 305.665 367.329C305.576 368.041 305.265 368.841 304.731 369.641C304.198 370.441 303.353 371.152 302.241 371.774C300.328 372.841 298.727 373.152 297.393 372.752C296.059 372.308 295.036 371.463 294.28 370.13L289.61 361.818C289.521 361.64 289.477 361.462 289.521 361.284C289.566 361.107 289.699 360.973 289.833 360.884L290.945 360.262C291.122 360.173 291.3 360.129 291.434 360.218C291.612 360.262 291.745 360.395 291.834 360.573L292.457 361.684C292.501 360.929 292.812 360.129 293.302 359.373C293.791 358.617 294.636 357.906 295.748 357.239C296.593 356.75 297.482 356.484 298.372 356.439ZM294.147 362.04C293.835 363.196 294.058 364.44 294.858 365.863L295.258 366.574L298.016 364.44C299.128 363.596 299.928 362.751 300.328 361.995C300.729 361.24 300.773 360.529 300.417 359.951C300.017 359.24 299.439 358.884 298.638 358.84C297.838 358.795 297.082 358.973 296.281 359.417C295.17 359.995 294.458 360.884 294.147 362.04Z" />
              <path d="M285.652 364.885C287.12 365.685 288.321 367.018 289.21 368.841L289.61 369.641L289.922 370.441C290.678 372.308 290.811 374.086 290.411 375.73C290.011 377.375 288.854 378.62 286.986 379.42C285.119 380.22 283.384 380.22 281.738 379.331L284.451 385.554C284.54 385.732 284.54 385.909 284.451 386.087C284.407 386.265 284.274 386.398 284.096 386.443L282.895 386.976C282.717 387.065 282.539 387.065 282.361 386.976C282.183 386.932 282.05 386.798 282.005 386.621L274.222 368.752C274.134 368.574 274.134 368.396 274.222 368.218C274.267 368.041 274.4 367.907 274.578 367.863L275.735 367.374C275.912 367.285 276.09 367.285 276.224 367.374C276.402 367.463 276.491 367.552 276.58 367.73L277.069 368.885C277.514 367.063 278.67 365.729 280.627 364.885C282.495 364.085 284.185 364.04 285.652 364.885ZM278.759 369.685C278.492 370.797 278.581 371.952 279.026 373.108C279.115 373.33 279.248 373.641 279.426 374.13C279.648 374.619 279.826 374.975 279.915 375.153C280.449 376.219 281.205 377.02 282.183 377.553C283.206 378.042 284.318 378.042 285.519 377.508C286.808 376.931 287.565 376.131 287.831 375.064C288.098 373.997 287.92 372.797 287.387 371.463L287.075 370.663C285.563 367.241 283.517 366.085 280.982 367.196C279.782 367.774 279.026 368.574 278.759 369.685Z" />
              <path d="M269.953 369.73C270.131 369.819 270.22 369.952 270.309 370.13L274.979 383.242C275.023 383.42 275.023 383.598 274.979 383.776C274.89 383.954 274.756 384.043 274.579 384.131L273.378 384.576C273.2 384.62 273.022 384.62 272.844 384.576C272.666 384.487 272.577 384.354 272.488 384.176L267.819 371.063C267.774 370.885 267.774 370.708 267.819 370.53C267.907 370.352 268.041 370.263 268.219 370.174L269.42 369.73C269.642 369.641 269.82 369.641 269.953 369.73ZM276.269 386.71C276.446 386.798 276.535 386.932 276.624 387.11L277.158 388.532C277.202 388.71 277.202 388.888 277.158 389.065C277.069 389.243 276.936 389.377 276.758 389.421L275.112 389.999C274.934 390.043 274.756 390.043 274.579 389.954C274.401 389.865 274.267 389.732 274.223 389.554L273.689 388.132C273.645 387.954 273.645 387.776 273.733 387.599C273.822 387.421 273.956 387.332 274.134 387.243L275.779 386.665C275.957 386.621 276.135 386.621 276.269 386.71Z" />
              <path d="M263.682 371.997C263.86 372.086 263.949 372.219 263.993 372.397L267.729 385.776C267.774 385.954 267.774 386.132 267.685 386.309C267.596 386.487 267.462 386.576 267.285 386.621L266.084 386.976C265.861 387.021 265.683 387.021 265.55 386.932C265.417 386.843 265.283 386.71 265.239 386.487L264.883 385.243C264.527 386.087 263.993 386.798 263.371 387.421C262.748 387.999 261.859 388.443 260.747 388.754C258.924 389.243 257.367 389.065 256.033 388.176C254.698 387.287 253.809 385.909 253.275 383.998L251.052 375.997C251.007 375.819 251.007 375.642 251.096 375.464C251.185 375.286 251.319 375.197 251.496 375.153L252.786 374.797C252.964 374.753 253.142 374.753 253.32 374.841C253.498 374.93 253.587 375.064 253.631 375.242L255.81 383.065C256.166 384.443 256.789 385.376 257.678 385.954C258.568 386.532 259.591 386.621 260.836 386.309C262.037 385.954 262.882 385.332 263.415 384.354C263.905 383.376 263.993 382.22 263.593 380.887L261.414 373.063C261.37 372.886 261.37 372.708 261.458 372.53C261.547 372.352 261.681 372.263 261.859 372.219L263.148 371.863C263.326 371.908 263.504 371.908 263.682 371.997Z" />
              <path d="M243.936 371.019C245.004 371.374 245.76 371.908 246.293 372.53C246.827 373.152 247.094 373.73 247.183 374.219C247.227 374.397 247.183 374.575 247.049 374.708C246.916 374.841 246.783 374.93 246.605 374.975L245.315 375.197C245.137 375.242 245.004 375.197 244.87 375.153C244.737 375.064 244.603 374.93 244.514 374.708C243.714 373.241 242.335 372.708 240.467 373.019C239 373.286 238.021 373.775 237.399 374.486C236.82 375.242 236.643 376.353 236.909 377.953L237.265 379.953C238.155 378.353 239.578 377.375 241.579 377.019C243.625 376.664 245.27 377.064 246.516 378.22C247.761 379.375 248.561 380.931 248.962 382.931L249.14 383.731L249.229 384.532C249.495 386.532 249.273 388.265 248.472 389.777C247.672 391.288 246.249 392.177 244.203 392.532C242.157 392.888 240.467 392.399 239.089 391.11L239.311 392.355C239.355 392.532 239.311 392.71 239.222 392.888C239.133 393.066 238.955 393.155 238.777 393.155L237.532 393.377C237.354 393.421 237.176 393.377 236.998 393.244C236.865 393.11 236.776 392.977 236.731 392.799L234.33 378.753C233.574 374.308 235.442 371.686 239.934 370.93C241.579 370.574 242.913 370.619 243.936 371.019ZM238.644 381.153C238.11 382.131 237.932 383.287 238.066 384.532C238.066 384.709 238.11 385.065 238.199 385.554C238.288 386.043 238.333 386.354 238.377 386.532C238.644 387.776 239.178 388.754 240.023 389.51C240.868 390.266 241.935 390.532 243.314 390.31C244.692 390.088 245.626 389.465 246.115 388.488C246.605 387.51 246.738 386.31 246.56 384.932L246.471 384.176L246.293 383.42C245.982 382.042 245.448 380.976 244.692 380.175C243.936 379.42 242.824 379.153 241.446 379.375C240.111 379.553 239.178 380.131 238.644 381.153Z" />
              <path d="M222.411 386.221C223.033 386.798 223.389 387.51 223.434 388.399C223.478 389.243 223.211 389.999 222.589 390.666C222.011 391.288 221.299 391.643 220.41 391.688C219.565 391.732 218.808 391.466 218.186 390.843C217.563 390.266 217.207 389.51 217.163 388.665C217.118 387.821 217.385 387.065 217.963 386.443C218.542 385.821 219.253 385.465 220.143 385.42C221.032 385.376 221.788 385.643 222.411 386.221Z" />
              <path d="M204.933 379.775C205.022 379.909 205.066 380.087 205.066 380.264L203.643 399.378C203.643 399.556 203.554 399.733 203.421 399.867C203.287 400 203.154 400.044 202.931 400L201.464 399.911C201.286 399.911 201.108 399.822 201.019 399.689C200.93 399.556 200.886 399.378 200.886 399.2L202.175 381.865L192.391 381.153C192.169 381.153 191.991 381.064 191.902 380.931C191.769 380.798 191.724 380.62 191.769 380.442L191.858 379.331C191.858 379.153 191.947 378.975 192.08 378.842C192.213 378.709 192.391 378.664 192.614 378.709L204.488 379.598C204.666 379.598 204.799 379.642 204.933 379.775Z" />
              <path d="M188.388 379.598C189.322 380.976 189.678 382.665 189.411 384.665L189.233 385.909L189.011 387.154C188.611 389.11 187.721 390.621 186.343 391.599C184.964 392.577 183.229 392.932 181.184 392.577C179.093 392.221 177.581 391.332 176.603 389.954C175.624 388.576 175.269 386.843 175.535 384.887C175.58 384.443 175.624 384.043 175.669 383.643C175.758 383.242 175.847 382.842 175.936 382.398C176.336 380.442 177.225 378.931 178.604 377.953C179.938 376.931 181.673 376.619 183.808 376.975C185.942 377.331 187.454 378.22 188.388 379.598ZM180.294 379.82C179.449 380.487 178.871 381.553 178.56 382.976C178.515 383.154 178.426 383.509 178.337 384.087C178.248 384.62 178.204 384.976 178.159 385.198C177.982 386.665 178.159 387.865 178.738 388.754C179.316 389.643 180.25 390.221 181.495 390.443C182.74 390.666 183.763 390.443 184.653 389.777C185.498 389.11 186.076 388.043 186.387 386.621L186.609 385.509L186.787 384.398C186.965 382.931 186.787 381.731 186.209 380.842C185.631 379.953 184.697 379.375 183.452 379.153C182.207 378.931 181.139 379.198 180.294 379.82Z" />
              <path d="M172.2 375.242C172.867 375.819 173.357 376.531 173.668 377.286C173.935 378.042 173.979 378.842 173.757 379.642C173.401 380.887 172.645 381.776 171.4 382.309C170.154 382.842 168.642 382.976 166.819 382.754L162.416 382.22L162.194 383.02C161.66 384.976 162.549 386.221 164.773 386.843C165.663 387.065 166.374 387.11 166.997 386.887C167.619 386.665 168.109 386.354 168.509 385.954C168.642 385.776 168.731 385.687 168.865 385.643C168.954 385.598 169.087 385.598 169.265 385.643L170.377 385.954C170.555 385.998 170.688 386.087 170.777 386.265C170.866 386.398 170.911 386.576 170.822 386.754C170.688 387.198 170.377 387.643 169.799 388.087C169.221 388.532 168.464 388.888 167.531 389.065C166.552 389.243 165.485 389.199 164.239 388.843C162.105 388.265 160.726 387.376 160.103 386.132C159.436 384.887 159.303 383.554 159.703 382.087L162.194 372.886C162.238 372.708 162.327 372.575 162.505 372.486C162.683 372.397 162.816 372.397 163.039 372.441L164.284 372.797C164.462 372.841 164.595 372.93 164.684 373.108C164.773 373.241 164.773 373.419 164.729 373.641L164.373 374.886C164.951 374.397 165.707 373.997 166.597 373.819C167.486 373.641 168.598 373.686 169.843 374.041C170.733 374.264 171.533 374.664 172.2 375.242ZM165.307 376.308C164.284 376.886 163.572 377.997 163.172 379.509L162.95 380.309L166.374 380.709C167.797 380.887 168.909 380.842 169.71 380.575C170.51 380.309 171.044 379.82 171.222 379.153C171.444 378.397 171.266 377.686 170.733 377.108C170.199 376.531 169.532 376.131 168.642 375.864C167.442 375.597 166.285 375.73 165.307 376.308Z" />
              <path d="M157.213 372.397C157.702 373.997 157.613 375.775 156.99 377.686L156.723 378.531L156.368 379.331C155.567 381.198 154.455 382.576 153.032 383.465C151.609 384.354 149.919 384.443 148.007 383.731C146.094 383.02 144.893 381.776 144.315 380.042L141.914 386.398C141.825 386.576 141.736 386.71 141.558 386.798C141.38 386.887 141.202 386.887 141.024 386.798L139.779 386.354C139.601 386.265 139.468 386.176 139.379 385.998C139.29 385.821 139.29 385.643 139.379 385.465L146.272 367.241C146.361 367.063 146.45 366.929 146.628 366.841C146.806 366.752 146.984 366.752 147.162 366.841L148.362 367.285C148.54 367.374 148.674 367.463 148.718 367.641C148.763 367.818 148.763 367.952 148.718 368.174L148.273 369.374C149.83 368.396 151.609 368.263 153.61 369.019C155.523 369.641 156.723 370.797 157.213 372.397ZM148.896 371.063C147.918 371.686 147.206 372.53 146.717 373.686C146.628 373.864 146.494 374.219 146.317 374.708C146.139 375.242 146.005 375.597 145.961 375.775C145.561 376.886 145.561 377.997 145.916 379.064C146.272 380.131 147.073 380.887 148.318 381.376C149.652 381.865 150.764 381.82 151.653 381.242C152.587 380.664 153.299 379.687 153.877 378.397L154.188 377.597C155.523 374.086 154.9 371.819 152.276 370.841C150.986 370.352 149.874 370.441 148.896 371.063Z" />
              <path d="M135.243 381.464C135.287 381.642 135.287 381.82 135.198 381.998L134.575 383.376C134.487 383.554 134.353 383.687 134.175 383.731C133.997 383.82 133.819 383.776 133.642 383.731L132.085 383.02C131.907 382.931 131.774 382.798 131.729 382.62C131.685 382.442 131.685 382.265 131.729 382.087L132.352 380.709C132.441 380.531 132.574 380.398 132.752 380.353C132.93 380.309 133.108 380.309 133.286 380.398L134.842 381.109C135.065 381.198 135.198 381.331 135.243 381.464ZM142.625 364.929C142.67 365.107 142.67 365.285 142.581 365.463L136.755 378.086C136.666 378.264 136.532 378.397 136.399 378.442C136.221 378.486 136.043 378.486 135.865 378.397L134.709 377.864C134.531 377.775 134.398 377.642 134.353 377.508C134.309 377.331 134.309 377.153 134.398 376.975L140.224 364.351C140.313 364.174 140.446 364.04 140.579 363.996C140.757 363.951 140.935 363.951 141.113 364.04L142.269 364.574C142.447 364.618 142.581 364.751 142.625 364.929Z" />
              <path d="M136.532 362.129C136.577 362.307 136.577 362.484 136.443 362.662L129.728 374.841C129.639 375.019 129.505 375.108 129.328 375.153C129.15 375.197 128.972 375.197 128.794 375.064L127.682 374.441C127.504 374.352 127.371 374.219 127.326 374.041C127.282 373.864 127.326 373.686 127.415 373.508L128.038 372.397C127.193 372.752 126.303 372.886 125.458 372.886C124.613 372.841 123.635 372.574 122.657 371.997C121.011 371.108 120.033 369.863 119.677 368.307C119.365 366.752 119.677 365.107 120.611 363.373L124.613 356.084C124.702 355.906 124.836 355.817 125.014 355.773C125.192 355.728 125.369 355.728 125.547 355.861L126.748 356.528C126.926 356.617 127.015 356.75 127.059 356.928C127.104 357.106 127.104 357.284 127.015 357.462L123.101 364.574C122.434 365.818 122.212 366.929 122.434 367.952C122.657 368.974 123.324 369.774 124.435 370.396C125.547 371.019 126.615 371.152 127.638 370.797C128.66 370.441 129.505 369.685 130.173 368.485L134.086 361.373C134.175 361.195 134.309 361.107 134.487 361.062C134.664 361.018 134.842 361.018 135.02 361.107L136.221 361.773C136.354 361.818 136.443 361.951 136.532 362.129Z" />
              <path d="M123.101 347.638C123.591 348.661 123.813 349.55 123.724 350.35C123.635 351.15 123.457 351.772 123.19 352.172C123.101 352.305 122.968 352.394 122.746 352.439C122.568 352.483 122.39 352.439 122.256 352.35L121.189 351.594C121.056 351.505 120.967 351.372 120.922 351.239C120.878 351.105 120.878 350.928 120.967 350.661C121.411 349.061 120.833 347.727 119.232 346.616C118.031 345.771 116.964 345.416 116.03 345.549C115.096 345.682 114.162 346.394 113.273 347.683L112.116 349.327C113.895 348.794 115.585 349.105 117.275 350.261C118.965 351.461 119.899 352.883 119.988 354.528C120.077 356.217 119.588 357.906 118.476 359.595L118.031 360.306L117.542 360.929C116.341 362.573 114.963 363.64 113.362 364.129C111.761 364.663 110.115 364.307 108.381 363.151C106.691 361.996 105.801 360.44 105.712 358.573L105.001 359.595C104.912 359.773 104.734 359.862 104.556 359.906C104.378 359.951 104.2 359.906 104.022 359.818L102.999 359.106C102.821 359.017 102.732 358.84 102.732 358.662C102.688 358.484 102.732 358.306 102.866 358.128L110.96 346.438C113.54 342.704 116.697 342.171 120.433 344.749C121.678 345.682 122.612 346.616 123.101 347.638ZM112.205 351.194C111.138 351.55 110.204 352.217 109.448 353.194C109.315 353.328 109.137 353.595 108.825 354.039C108.559 354.439 108.381 354.706 108.292 354.884C107.625 355.95 107.313 357.062 107.402 358.173C107.447 359.284 108.069 360.262 109.181 361.018C110.337 361.818 111.449 362.04 112.472 361.684C113.495 361.329 114.429 360.573 115.274 359.462L115.719 358.84L116.164 358.173C116.92 356.973 117.275 355.817 117.275 354.75C117.275 353.639 116.653 352.706 115.496 351.905C114.385 351.061 113.273 350.839 112.205 351.194Z" />
              <path d="M97.0397 343.504C97.0841 344.349 96.8173 345.105 96.2836 345.771C95.7054 346.394 94.9939 346.749 94.1044 346.794C93.2149 346.838 92.4589 346.571 91.8363 345.994C91.2136 345.416 90.8578 344.705 90.8134 343.86C90.7689 343.016 91.0357 342.26 91.6139 341.593C92.192 340.971 92.9036 340.615 93.7486 340.571C94.5936 340.526 95.3497 340.793 96.0168 341.326C96.6394 341.904 96.9952 342.615 97.0397 343.504Z" />
              <path d="M89.0346 326.703C89.0346 326.88 88.9457 327.014 88.7678 327.147L74.3583 339.815C74.2249 339.949 74.047 339.993 73.8691 339.993C73.6912 339.993 73.5578 339.904 73.4244 339.771L72.446 338.659C72.3125 338.526 72.2681 338.348 72.2681 338.171C72.2681 337.993 72.357 337.859 72.5349 337.726L85.6101 326.214L79.117 318.879C78.9836 318.702 78.8946 318.568 78.9391 318.346C78.9391 318.168 79.0281 317.99 79.1615 317.901L80.0065 317.146C80.1399 317.012 80.3178 316.968 80.4957 316.968C80.6736 316.968 80.8515 317.057 80.9849 317.235L88.8567 326.169C88.9901 326.347 89.0791 326.525 89.0346 326.703Z" />
              <path d="M77.3826 315.012C77.0712 316.657 76.1373 318.124 74.5807 319.368L73.5578 320.124L72.5349 320.835C70.8449 321.946 69.1994 322.391 67.5094 322.169C65.8194 321.946 64.3518 320.968 63.151 319.235C61.9057 317.546 61.4165 315.857 61.7278 314.168C62.0392 312.479 62.9286 311.056 64.5297 309.812C64.841 309.545 65.1968 309.278 65.5081 309.011C65.8194 308.789 66.1752 308.523 66.5755 308.3C68.2655 307.189 69.911 306.745 71.601 306.967C73.2465 307.189 74.7141 308.167 76.0039 309.9C77.1602 311.678 77.6494 313.368 77.3826 315.012ZM71.4231 309.5C70.3557 309.367 69.1994 309.723 67.9542 310.567C67.7763 310.656 67.4649 310.878 67.0202 311.19C66.5755 311.501 66.2642 311.723 66.1307 311.856C64.9744 312.79 64.2628 313.768 64.0849 314.834C63.8626 315.901 64.1294 316.924 64.8855 317.946C65.6415 318.968 66.531 319.546 67.5984 319.635C68.6657 319.768 69.822 319.413 71.0673 318.568L72.0012 317.946L72.8907 317.279C74.047 316.346 74.7586 315.368 74.9365 314.301C75.1589 313.234 74.892 312.212 74.136 311.19C73.3799 310.212 72.4905 309.634 71.4231 309.5Z" />
              <path d="M68.8436 300.566C68.9325 301.455 68.7991 302.3 68.4433 303.055C68.0875 303.811 67.5983 304.389 66.8867 304.833C65.7749 305.5 64.5741 305.589 63.3288 305.1C62.0836 304.611 60.8828 303.633 59.7265 302.211L56.9691 298.744L56.2575 299.188C54.5231 300.211 54.2562 301.722 55.4125 303.722C55.8573 304.478 56.391 305.011 56.9691 305.322C57.5473 305.589 58.1254 305.722 58.6591 305.722C58.8815 305.678 59.0149 305.678 59.1483 305.722C59.2373 305.767 59.3262 305.856 59.4152 305.989L59.9933 306.967C60.0823 307.1 60.1267 307.278 60.0823 307.456C60.0378 307.634 59.9488 307.767 59.771 307.856C59.3707 308.078 58.837 308.167 58.081 308.123C57.3694 308.078 56.5689 307.767 55.7683 307.234C54.9233 306.7 54.2118 305.9 53.5447 304.789C52.4328 302.922 52.077 301.277 52.4773 299.944C52.8775 298.61 53.7225 297.544 55.0568 296.788L63.2844 291.943C63.4623 291.854 63.6402 291.81 63.818 291.854C63.9959 291.898 64.1294 291.987 64.2183 292.165L64.8854 293.276C64.9744 293.454 65.0188 293.632 64.9744 293.765C64.9299 293.943 64.7965 294.076 64.663 294.165L63.5512 294.832C64.3073 294.877 65.1078 295.143 65.9083 295.632C66.7088 296.121 67.4204 296.921 68.0875 298.032C68.4878 298.833 68.7546 299.677 68.8436 300.566ZM63.1509 296.521C61.9946 296.255 60.7494 296.521 59.3707 297.321L58.6591 297.721L60.8383 300.433C61.7278 301.544 62.5728 302.3 63.3288 302.7C64.0849 303.1 64.7965 303.1 65.4191 302.744C66.1307 302.344 66.4865 301.722 66.5309 300.966C66.5754 300.166 66.353 299.41 65.9083 298.61C65.1967 297.455 64.2628 296.788 63.1509 296.521Z" />
              <path d="M60.0824 288.076C59.3263 289.587 58.0366 290.787 56.2132 291.721L55.4126 292.121L54.6121 292.476C52.7442 293.276 51.0098 293.454 49.3642 293.099C47.7187 292.743 46.4735 291.632 45.584 289.765C44.739 287.942 44.6945 286.164 45.5395 284.52L39.4022 287.409C39.2243 287.498 39.0464 287.498 38.8685 287.453C38.6906 287.409 38.5572 287.276 38.5127 287.098L37.9345 285.898C37.8456 285.72 37.8456 285.542 37.8901 285.364C37.9345 285.187 38.0679 285.053 38.2458 285.009L55.9463 276.741C56.1242 276.652 56.3021 276.652 56.48 276.697C56.6579 276.741 56.7913 276.874 56.8358 277.052L57.3695 278.208C57.4584 278.386 57.4584 278.564 57.414 278.697C57.3695 278.83 57.2361 278.964 57.0582 279.052L55.9019 279.586C57.7253 279.986 59.0595 281.097 59.9934 283.008C60.8384 284.92 60.8829 286.609 60.0824 288.076ZM55.1013 281.319C53.9895 281.097 52.8332 281.186 51.6769 281.675C51.4545 281.764 51.1432 281.897 50.654 282.119C50.1648 282.342 49.809 282.52 49.6311 282.608C48.5637 283.142 47.8077 283.942 47.3185 284.92C46.8292 285.942 46.8737 287.053 47.4519 288.254C48.03 289.543 48.875 290.254 49.9424 290.476C51.0098 290.698 52.2105 290.52 53.5448 289.987L54.3453 289.631C57.7697 288.031 58.8816 285.987 57.6808 283.453C57.0582 282.297 56.2132 281.542 55.1013 281.319Z" />
              <path d="M38.0231 279.23C37.9342 279.408 37.8007 279.497 37.6228 279.586L36.1997 280.119C36.0218 280.208 35.8439 280.208 35.666 280.119C35.4881 280.03 35.3547 279.941 35.3102 279.719L34.6876 278.119C34.5986 277.941 34.6431 277.763 34.6876 277.586C34.7765 277.408 34.91 277.274 35.0878 277.23L36.511 276.697C36.6889 276.608 36.8668 276.652 37.0447 276.697C37.2226 276.786 37.3115 276.919 37.4005 277.097L38.0231 278.697C38.1121 278.875 38.1121 279.052 38.0231 279.23ZM54.8786 272.518C54.7896 272.696 54.6562 272.785 54.4783 272.874L41.492 277.852C41.3141 277.941 41.1363 277.941 40.9584 277.852C40.7805 277.763 40.6915 277.63 40.6026 277.452L40.1578 276.252C40.0689 276.074 40.0689 275.897 40.1578 275.719C40.2468 275.541 40.3802 275.452 40.5581 275.363L53.5444 270.385C53.7223 270.296 53.9002 270.296 54.0781 270.385C54.256 270.474 54.3449 270.607 54.4339 270.785L54.8786 271.985C54.9675 272.163 54.9675 272.341 54.8786 272.518Z" />
              <path d="M52.4326 266.251C52.3436 266.429 52.2102 266.518 52.0323 266.562L38.7347 270.607C38.5568 270.651 38.3789 270.651 38.201 270.563C38.0231 270.474 37.9342 270.34 37.8897 270.162L37.5339 268.962C37.4894 268.74 37.4894 268.562 37.5784 268.429C37.6673 268.296 37.8007 268.162 38.0231 268.118L39.2684 267.762C38.4234 267.407 37.6673 266.918 37.0892 266.295C36.511 265.673 36.0218 264.784 35.7105 263.717C35.1768 261.939 35.3102 260.339 36.1552 259.006C37.0002 257.672 38.3789 256.694 40.2913 256.116L48.252 253.716C48.4299 253.672 48.6078 253.672 48.7857 253.761C48.9636 253.849 49.0526 253.983 49.097 254.161L49.4973 255.45C49.5418 255.627 49.5418 255.805 49.4528 255.983C49.3639 256.161 49.2305 256.25 49.0526 256.294L41.2697 258.65C39.9355 259.05 38.9571 259.717 38.4234 260.606C37.8897 261.495 37.8007 262.517 38.1565 263.762C38.5123 264.962 39.1794 265.806 40.1578 266.295C41.1363 266.784 42.2926 266.829 43.6268 266.429L51.4097 264.073C51.5876 264.028 51.7655 264.028 51.9433 264.117C52.1212 264.206 52.2102 264.34 52.2547 264.517L52.6549 265.806C52.5215 265.94 52.5215 266.118 52.4326 266.251Z" />
              <path d="M52.9664 246.56C52.6106 247.627 52.1214 248.427 51.4988 248.96C50.8762 249.493 50.3425 249.805 49.8088 249.893C49.6309 249.938 49.453 249.893 49.3196 249.76C49.1862 249.671 49.0972 249.493 49.0528 249.316L48.7859 248.027C48.7414 247.849 48.7859 247.715 48.8304 247.582C48.8749 247.449 49.0528 247.315 49.2307 247.182C50.6538 246.337 51.1875 244.96 50.8317 243.093C50.5649 241.67 50.0312 240.648 49.2751 240.07C48.5191 239.492 47.3628 239.359 45.8062 239.67L43.8049 240.07C45.4504 240.915 46.4733 242.337 46.8291 244.337C47.2293 246.382 46.8736 248.027 45.7617 249.271C44.6499 250.516 43.0933 251.405 41.1365 251.849L40.3359 252.027L39.5354 252.16C37.5341 252.472 35.7996 252.294 34.2875 251.538C32.7754 250.782 31.8415 249.405 31.4412 247.36C31.041 245.36 31.4857 243.626 32.731 242.204L31.4857 242.426C31.3078 242.47 31.1299 242.426 30.952 242.337C30.7741 242.248 30.6852 242.115 30.6407 241.893L30.4183 240.648C30.3739 240.47 30.4183 240.292 30.5073 240.115C30.5962 239.981 30.7741 239.848 30.952 239.848L44.9167 237.136C49.3641 236.292 51.988 238.07 52.8775 242.559C53.3667 244.159 53.3222 245.493 52.9664 246.56ZM42.693 241.492C41.6701 240.959 40.5583 240.826 39.313 241.004C39.1351 241.004 38.7794 241.092 38.2902 241.181C37.8009 241.27 37.4896 241.359 37.3117 241.404C36.1109 241.715 35.1325 242.293 34.3765 243.137C33.6204 243.982 33.3981 245.093 33.6649 246.426C33.9317 247.804 34.5544 248.738 35.5773 249.182C36.5557 249.627 37.7565 249.76 39.1796 249.538L39.9357 249.405L40.6917 249.227C42.0704 248.916 43.1378 248.338 43.8938 247.538C44.6499 246.738 44.8722 245.671 44.6054 244.293C44.3386 242.915 43.7159 241.981 42.693 241.492Z" />
              <path d="M37.2673 225.402C36.6891 226.068 35.9775 226.424 35.1325 226.468C34.2875 226.513 33.5315 226.291 32.8644 225.713C32.1973 225.135 31.8415 224.424 31.797 223.579C31.7525 222.735 31.9749 221.979 32.5531 221.312C33.1312 220.646 33.8428 220.334 34.6878 220.246C35.5328 220.201 36.2888 220.423 36.9115 221.001C37.5786 221.579 37.9344 222.29 37.9788 223.135C38.0678 223.979 37.8009 224.735 37.2673 225.402Z" />
              <path d="M48.2077 200.777C48.0743 200.865 47.8964 200.91 47.7185 200.91L28.5948 199.176C28.4169 199.176 28.239 199.087 28.1501 198.954C28.0167 198.821 27.9722 198.687 28.0167 198.465L28.1501 197.043C28.1501 196.865 28.239 196.687 28.3725 196.598C28.5059 196.509 28.6838 196.465 28.8617 196.465L46.2064 198.065L47.0958 188.42C47.0958 188.197 47.1848 188.064 47.3182 187.931C47.4516 187.797 47.6295 187.753 47.8074 187.797L48.8748 187.842C49.0527 187.842 49.2306 187.931 49.3195 188.108C49.4529 188.242 49.4974 188.42 49.4529 188.597L48.4301 200.332C48.4301 200.51 48.3411 200.643 48.2077 200.777Z" />
              <path d="M48.6077 184.464C47.1846 185.353 45.4946 185.664 43.4933 185.397L42.248 185.175L41.0027 184.908C39.0459 184.464 37.5783 183.575 36.5999 182.197C35.6214 180.819 35.3101 179.13 35.7104 177.085C36.0662 175.04 37.0001 173.529 38.3788 172.64C39.802 171.706 41.492 171.395 43.4933 171.662C43.938 171.706 44.3383 171.751 44.7385 171.84C45.1388 171.929 45.5391 172.018 45.9838 172.107C47.9406 172.551 49.4083 173.44 50.4311 174.774C51.4096 176.107 51.7209 177.841 51.3206 179.93C50.9204 182.063 50.0309 183.575 48.6077 184.464ZM48.5188 176.463C47.8517 175.618 46.8288 174.996 45.3612 174.685C45.1833 174.64 44.8275 174.551 44.2493 174.462C43.7156 174.373 43.3598 174.285 43.1375 174.285C41.6699 174.062 40.4691 174.24 39.5796 174.818C38.6901 175.396 38.112 176.285 37.8896 177.485C37.6672 178.685 37.8896 179.752 38.5122 180.596C39.1349 181.441 40.2022 182.063 41.6698 182.374L42.7817 182.597L43.8935 182.774C45.3612 182.997 46.5619 182.819 47.4514 182.241C48.3409 181.663 48.919 180.774 49.1414 179.574C49.3638 178.374 49.1414 177.307 48.5188 176.463Z" />
              <path d="M53.1446 168.595C52.5664 169.262 51.8548 169.706 51.0988 170.017C50.2983 170.284 49.5422 170.329 48.7417 170.106C47.4964 169.751 46.607 168.951 46.1177 167.75C45.6285 166.506 45.4506 164.995 45.673 163.217L46.2512 158.861L45.4506 158.638C43.4938 158.105 42.2485 158.905 41.6259 161.127C41.4035 161.972 41.3591 162.728 41.5814 163.35C41.8038 163.972 42.0706 164.461 42.4709 164.817C42.6488 164.95 42.7377 165.039 42.7822 165.172C42.8267 165.261 42.8267 165.395 42.7822 165.528L42.4709 166.595C42.4264 166.773 42.3375 166.906 42.1596 166.995C41.9817 167.084 41.8483 167.084 41.6704 167.039C41.2256 166.906 40.7809 166.595 40.3362 166.017C39.8914 165.439 39.5357 164.683 39.3578 163.75C39.1799 162.817 39.2688 161.705 39.5801 160.505C40.1583 158.416 41.0922 157.083 42.293 156.46C43.5383 155.838 44.8725 155.749 46.3401 156.149L55.5017 158.727C55.6796 158.772 55.813 158.905 55.9019 159.038C55.9909 159.216 55.9909 159.349 55.9464 159.527L55.5906 160.772C55.5461 160.95 55.4572 161.083 55.2793 161.172C55.1459 161.261 54.968 161.261 54.7456 161.216L53.5004 160.861C53.9896 161.439 54.3454 162.194 54.5677 163.083C54.7456 163.972 54.7011 165.039 54.3454 166.284C54.1675 167.128 53.7672 167.928 53.1446 168.595ZM52.1661 161.75C51.588 160.727 50.5206 160.016 48.9641 159.572L48.1635 159.349L47.7188 162.728C47.5409 164.106 47.5854 165.217 47.8077 166.017C48.0746 166.817 48.5193 167.35 49.2309 167.528C49.9869 167.75 50.6985 167.617 51.2767 167.084C51.8548 166.595 52.2996 165.884 52.5219 165.039C52.8777 163.839 52.7443 162.728 52.1661 161.75Z" />
              <path d="M56.1239 153.838C54.5229 154.282 52.7439 154.193 50.8316 153.527L49.9866 153.215L49.1861 152.86C47.3182 152.06 45.984 150.948 45.0945 149.482C44.205 148.059 44.1605 146.37 44.8721 144.503C45.5837 142.636 46.829 141.436 48.6079 140.903L42.2927 138.458C42.1148 138.369 41.9813 138.28 41.8924 138.103C41.8034 137.925 41.8034 137.747 41.8924 137.569L42.3816 136.369C42.4706 136.191 42.5595 136.058 42.7374 136.013C42.9153 135.925 43.0932 135.925 43.2711 136.013L61.4163 143.081C61.5942 143.17 61.7276 143.259 61.8166 143.437C61.9055 143.614 61.9055 143.792 61.8166 143.97L61.3718 145.126C61.2829 145.303 61.1939 145.437 61.016 145.481C60.8381 145.526 60.7047 145.526 60.4823 145.481L59.326 145.037C60.3045 146.592 60.4379 148.37 59.6818 150.282C58.8813 152.193 57.725 153.393 56.1239 153.838ZM57.5471 145.659C56.9245 144.681 56.0795 143.97 54.9232 143.481C54.7453 143.392 54.3895 143.259 53.9003 143.036C53.411 142.859 53.0108 142.725 52.8329 142.636C51.7211 142.236 50.6092 142.192 49.5418 142.548C48.4745 142.903 47.7184 143.659 47.2292 144.903C46.74 146.192 46.7845 147.304 47.3626 148.193C47.9408 149.126 48.9192 149.837 50.209 150.415L51.0095 150.771C54.5229 152.149 56.7466 151.526 57.7695 148.993C58.1697 147.704 58.1252 146.592 57.5471 145.659Z" />
              <path d="M47.1403 131.968C46.9624 132.013 46.7845 132.013 46.6066 131.924L45.2279 131.257C45.05 131.168 44.9166 131.035 44.8722 130.857C44.7832 130.679 44.8277 130.502 44.8722 130.324L45.5837 128.768C45.6727 128.59 45.8061 128.457 45.984 128.413C46.1619 128.368 46.3398 128.368 46.5177 128.457L47.8964 129.124C48.0743 129.213 48.2077 129.346 48.2521 129.524C48.2966 129.702 48.2966 129.879 48.2077 130.057L47.4961 131.613C47.4516 131.791 47.3182 131.924 47.1403 131.968ZM63.6845 139.48C63.5066 139.525 63.3287 139.525 63.1508 139.436L50.5648 133.524C50.3869 133.435 50.2535 133.302 50.209 133.169C50.1645 132.991 50.1645 132.813 50.2535 132.635L50.7871 131.48C50.8761 131.302 51.0095 131.168 51.1429 131.124C51.3208 131.079 51.4987 131.079 51.6766 131.168L64.2626 137.08C64.4405 137.169 64.574 137.302 64.6184 137.436C64.6629 137.614 64.6629 137.791 64.574 137.969L64.0403 139.125C63.9513 139.303 63.8179 139.436 63.6845 139.48Z" />
              <path d="M66.4421 133.524C66.2642 133.569 66.0863 133.569 65.9084 133.435L53.7671 126.635C53.5892 126.546 53.5003 126.412 53.4558 126.234C53.4113 126.057 53.4113 125.879 53.5447 125.746L54.1674 124.679C54.2563 124.501 54.3897 124.368 54.5676 124.323C54.7455 124.279 54.879 124.323 55.1013 124.412L56.2132 125.034C55.8574 124.19 55.724 123.301 55.724 122.456C55.7684 121.612 56.0353 120.678 56.6134 119.7C57.5029 118.1 58.7482 117.122 60.3047 116.811C61.8613 116.5 63.5068 116.856 65.1968 117.789L72.446 121.834C72.6239 121.923 72.7129 122.056 72.7573 122.234C72.8018 122.412 72.8018 122.59 72.6684 122.723L72.0013 123.879C71.9123 124.056 71.7789 124.145 71.601 124.19C71.4231 124.234 71.2452 124.234 71.0673 124.101L63.9516 120.145C62.7063 119.478 61.5945 119.211 60.5716 119.434C59.5487 119.656 58.7482 120.278 58.1255 121.389C57.5029 122.456 57.3695 123.523 57.7253 124.545C58.0811 125.568 58.8371 126.412 60.0379 127.123L67.1537 131.079C67.3316 131.168 67.4205 131.302 67.465 131.48C67.5095 131.657 67.5095 131.835 67.376 131.968L66.7089 133.124C66.7534 133.346 66.62 133.435 66.4421 133.524Z" />
              <path d="M80.9401 120.412C79.9172 120.901 79.0277 121.078 78.2272 120.989C77.4267 120.901 76.804 120.723 76.4038 120.456C76.2703 120.367 76.1814 120.234 76.1369 120.012C76.0924 119.834 76.1369 119.656 76.2259 119.523L76.9374 118.5C77.0264 118.367 77.1598 118.278 77.2932 118.234C77.4267 118.189 77.6045 118.189 77.8714 118.278C79.4724 118.722 80.8066 118.189 81.9185 116.589C82.7635 115.389 83.1193 114.366 82.9858 113.433C82.8524 112.5 82.1853 111.566 80.8511 110.677L79.2056 109.521C79.7393 111.255 79.428 112.944 78.3161 114.589C77.1598 116.278 75.7367 117.122 74.0467 117.211C72.3567 117.3 70.7111 116.767 68.9767 115.655L68.2651 115.211L67.6425 114.722C66.0414 113.522 64.9741 112.099 64.4404 110.544C63.9067 108.944 64.2625 107.299 65.4188 105.654C66.5751 104.01 68.0872 103.121 69.9996 103.076L68.9767 102.365C68.7988 102.232 68.7098 102.098 68.6654 101.92C68.6209 101.743 68.6654 101.565 68.7543 101.432L69.4659 100.409C69.5548 100.231 69.7327 100.143 69.9106 100.143C70.0885 100.098 70.2664 100.143 70.4443 100.276L82.0964 108.41C85.7877 110.988 86.3658 114.144 83.7864 117.833C82.8969 119.034 81.9185 119.923 80.9401 120.412ZM77.3822 109.566C77.0264 108.499 76.3593 107.566 75.3809 106.81C75.2474 106.677 74.9806 106.499 74.5359 106.188C74.1356 105.921 73.8688 105.743 73.6909 105.654C72.6235 104.988 71.5561 104.676 70.3998 104.721C69.288 104.765 68.3096 105.343 67.5535 106.454C66.753 107.61 66.5306 108.677 66.9309 109.699C67.2867 110.722 68.0427 111.655 69.1546 112.5L69.7772 112.944L70.4443 113.389C71.6451 114.144 72.7569 114.544 73.8688 114.5C74.9361 114.5 75.9145 113.922 76.7151 112.766C77.4711 111.744 77.6935 110.677 77.3822 109.566Z" />
              <path d="M84.9872 94.6752C84.1422 94.7196 83.3862 94.453 82.7191 93.8751C82.0964 93.2973 81.7407 92.5861 81.6962 91.6971C81.6517 90.8525 81.9186 90.0969 82.4522 89.4746C83.0304 88.8523 83.742 88.4967 84.587 88.4967C85.432 88.4522 86.188 88.7189 86.8551 89.2968C87.4777 89.8746 87.8335 90.5858 87.878 91.4304C87.9225 92.2749 87.7001 93.0306 87.122 93.6529C86.5438 94.2752 85.8322 94.6307 84.9872 94.6752Z" />
              <path d="M101.665 86.8521C101.487 86.8521 101.353 86.7632 101.22 86.5854L88.5895 72.1392C88.4561 72.0059 88.4116 71.8281 88.4116 71.6503C88.4116 71.4725 88.5006 71.3391 88.634 71.2058L89.7014 70.2723C89.8348 70.139 90.0127 70.0945 90.1906 70.0945C90.3685 70.0945 90.5019 70.1834 90.6353 70.3612L102.109 83.4295L109.403 77.0731C109.581 76.9398 109.714 76.8509 109.892 76.8953C110.07 76.8953 110.204 76.9842 110.337 77.1176L111.093 77.9621C111.227 78.0955 111.271 78.2733 111.271 78.4511C111.271 78.6289 111.182 78.8067 111.049 78.94L102.198 86.6743C102.021 86.8521 101.843 86.8965 101.665 86.8521Z" />
              <path d="M113.228 75.3841C111.582 75.0729 110.159 74.1395 108.914 72.5393L108.158 71.5169L107.446 70.4946C106.335 68.8055 105.89 67.1609 106.112 65.4718C106.335 63.8271 107.313 62.3603 109.003 61.1602C110.693 59.96 112.383 59.4711 114.029 59.7822C115.674 60.0934 117.142 61.0268 118.387 62.627C118.654 62.9382 118.921 63.2938 119.187 63.6049C119.41 63.916 119.677 64.2716 119.899 64.6717C121.011 66.3608 121.456 68.0054 121.233 69.6501C121.011 71.2947 120.032 72.7615 118.342 74.0061C116.652 75.2507 114.829 75.6952 113.228 75.3841ZM118.654 69.5167C118.787 68.4499 118.431 67.2942 117.586 66.0496C117.497 65.8718 117.275 65.5607 116.964 65.1162C116.652 64.6717 116.43 64.3605 116.297 64.2272C115.363 63.0715 114.384 62.3603 113.361 62.1381C112.294 61.9158 111.271 62.1825 110.293 62.8937C109.27 63.6049 108.736 64.4939 108.603 65.5607C108.469 66.6275 108.825 67.7832 109.67 69.0278L110.293 69.9612L110.96 70.8502C111.894 72.0059 112.872 72.7171 113.895 72.9393C114.962 73.1616 115.985 72.8949 116.964 72.1837C117.942 71.4725 118.52 70.5835 118.654 69.5167Z" />
              <path d="M127.46 67.0275C126.57 67.1164 125.77 66.9386 125.014 66.6275C124.258 66.2719 123.679 65.7385 123.279 65.0717C122.612 63.9605 122.523 62.8048 123.012 61.5602C123.502 60.3156 124.435 59.1155 125.859 58.0042L129.283 55.2928L128.838 54.5372C127.815 52.8036 126.303 52.4925 124.347 53.6926C123.59 54.1371 123.057 54.6705 122.79 55.2483C122.523 55.8262 122.39 56.404 122.434 56.9374C122.479 57.1597 122.479 57.293 122.434 57.3819C122.39 57.4708 122.301 57.5597 122.167 57.6486L121.189 58.2265C121.055 58.3154 120.878 58.3154 120.7 58.2709C120.522 58.2265 120.388 58.1376 120.299 57.9598C120.077 57.5597 119.988 57.0263 120.033 56.2707C120.077 55.5595 120.388 54.7594 120.922 53.9593C121.456 53.1592 122.256 52.4036 123.324 51.7813C125.192 50.67 126.793 50.3589 128.082 50.7589C129.417 51.159 130.439 52.0035 131.24 53.337L136.043 61.5602C136.132 61.738 136.177 61.9158 136.132 62.0936C136.088 62.2714 135.999 62.4047 135.821 62.4936L134.709 63.1604C134.531 63.2493 134.398 63.2937 134.22 63.2493C134.042 63.2048 133.908 63.0715 133.819 62.9381L133.152 61.8269C133.108 62.5825 132.841 63.3826 132.352 64.1383C131.863 64.9384 131.062 65.6496 129.995 66.2719C129.194 66.6719 128.349 66.9386 127.46 67.0275ZM131.507 61.3824C131.774 60.2267 131.507 58.9821 130.706 57.6042L130.306 56.893L127.638 59.0266C126.526 59.9156 125.814 60.7157 125.414 61.4713C125.058 62.2269 125.014 62.9381 125.369 63.516C125.77 64.2272 126.348 64.5828 127.148 64.6272C127.904 64.6717 128.705 64.4494 129.461 64.0049C130.528 63.4271 131.24 62.5381 131.507 61.3824Z" />
              <path d="M139.823 58.4487C138.356 57.6486 137.155 56.3596 136.221 54.5372L135.821 53.7371L135.465 52.937C134.709 51.0701 134.486 49.3366 134.842 47.6919C135.198 46.0473 136.31 44.8027 138.133 43.9581C139.957 43.1136 141.691 43.1136 143.292 43.9581L140.446 37.8241C140.357 37.6463 140.357 37.4685 140.401 37.2907C140.446 37.1129 140.579 36.9795 140.757 36.9351L141.958 36.4017C142.136 36.3128 142.314 36.3128 142.492 36.3572C142.67 36.4017 142.803 36.535 142.848 36.7128L151.031 54.4038C151.12 54.5816 151.12 54.7594 151.075 54.9372C151.031 55.115 150.897 55.2484 150.719 55.2928L149.608 55.8262C149.43 55.9151 149.252 55.9151 149.118 55.8262C148.94 55.7818 148.851 55.6484 148.763 55.4706L148.229 54.3149C147.873 56.1374 146.717 57.4708 144.849 58.3598C142.936 59.2044 141.291 59.2044 139.823 58.4487ZM146.494 53.5148C146.717 52.4036 146.583 51.2923 146.139 50.0922C146.05 49.8699 145.916 49.5588 145.694 49.0699C145.471 48.5809 145.294 48.2253 145.205 48.0475C144.671 46.9807 143.915 46.1806 142.892 45.6917C141.869 45.2027 140.802 45.2472 139.601 45.7806C138.356 46.3584 137.6 47.203 137.377 48.2698C137.155 49.3366 137.333 50.5367 137.866 51.8702L138.178 52.6703C139.734 56.0929 141.78 57.2041 144.271 56.0485C145.56 55.4706 146.272 54.6261 146.494 53.5148Z" />
              <path d="M148.54 36.4906C148.362 36.4017 148.273 36.2683 148.184 36.0905L147.651 34.6682C147.562 34.4904 147.562 34.3126 147.651 34.1348C147.74 33.957 147.829 33.8236 148.006 33.7792L149.608 33.1569C149.785 33.068 149.963 33.1124 150.141 33.1569C150.319 33.2458 150.453 33.3791 150.497 33.5569L151.031 34.9793C151.12 35.1571 151.075 35.3349 151.031 35.5127C150.942 35.6905 150.808 35.7794 150.63 35.8683L149.029 36.4906C148.896 36.5795 148.718 36.5795 148.54 36.4906ZM155.211 53.3815C155.033 53.2926 154.944 53.1592 154.855 52.9814L149.919 39.9577C149.83 39.7799 149.83 39.6021 149.919 39.4243C150.008 39.2465 150.097 39.1576 150.319 39.0687L151.52 38.6242C151.698 38.5353 151.876 38.5797 152.054 38.6242C152.231 38.7131 152.32 38.8464 152.409 39.0242L157.346 52.048C157.435 52.2258 157.435 52.4036 157.346 52.5814C157.257 52.7592 157.168 52.8481 156.946 52.937L155.745 53.3815C155.523 53.4259 155.345 53.4259 155.211 53.3815Z" />
              <path d="M161.348 50.9812C161.171 50.8923 161.082 50.7589 161.037 50.5811L157.079 37.2462C157.035 37.0684 157.035 36.8906 157.123 36.7128C157.212 36.535 157.346 36.4461 157.524 36.4017L158.725 36.0461C158.947 36.0016 159.08 36.0016 159.258 36.0905C159.436 36.1794 159.525 36.3128 159.57 36.535L159.925 37.7796C160.281 36.9351 160.77 36.1794 161.393 35.6016C162.016 35.0237 162.861 34.5348 163.972 34.2237C165.751 33.6903 167.308 33.8681 168.642 34.7126C169.976 35.5571 170.91 36.9351 171.488 38.8464L173.846 46.8029C173.89 46.9807 173.89 47.1585 173.801 47.3363C173.712 47.5141 173.579 47.603 173.401 47.6475L172.111 48.0475C171.933 48.0919 171.755 48.0919 171.577 48.003C171.399 47.9142 171.311 47.7808 171.266 47.603L168.953 39.8243C168.553 38.4908 167.931 37.5129 167.041 36.9795C166.152 36.4017 165.129 36.3128 163.928 36.6684C162.727 37.024 161.927 37.6907 161.437 38.6686C160.948 39.6465 160.904 40.8022 161.304 42.1357L163.617 49.9144C163.661 50.0922 163.661 50.27 163.572 50.4478C163.483 50.6256 163.35 50.7145 163.172 50.7589L161.882 51.159C161.704 51.1145 161.526 51.0701 161.348 50.9812Z" />
              <path d="M180.828 51.6924C179.76 51.3368 179.004 50.8478 178.471 50.2255C177.937 49.6032 177.626 49.0698 177.537 48.5364C177.492 48.3586 177.537 48.1808 177.67 48.0475C177.759 47.9142 177.937 47.8253 178.07 47.7808L179.316 47.5586C179.494 47.5141 179.627 47.5586 179.76 47.603C179.894 47.6475 180.027 47.8253 180.116 48.003C180.917 49.4254 182.295 50.0033 184.163 49.6477C185.586 49.381 186.565 48.892 187.143 48.1364C187.721 47.3808 187.855 46.2251 187.543 44.6693L187.187 42.6691C186.342 44.3137 184.964 45.2916 182.962 45.6917C180.961 46.0917 179.316 45.6917 178.07 44.5804C176.825 43.4692 175.98 41.9134 175.535 39.9132L175.358 39.1131L175.224 38.313C174.913 36.3128 175.135 34.5793 175.891 33.068C176.647 31.5567 178.026 30.6232 180.027 30.2232C182.029 29.8676 183.719 30.3121 185.097 31.5567L184.875 30.3121C184.83 30.1343 184.875 29.9565 184.964 29.7787C185.053 29.6009 185.186 29.512 185.409 29.4675L186.654 29.2453C186.832 29.2008 187.01 29.2453 187.143 29.3786C187.276 29.512 187.365 29.6453 187.41 29.8231L190.034 43.8248C190.879 48.2697 189.1 50.8923 184.652 51.7368C183.185 52.0924 181.895 52.048 180.828 51.6924ZM185.853 41.4689C186.342 40.4466 186.52 39.3354 186.342 38.0908C186.342 37.913 186.254 37.5574 186.165 37.0684C186.076 36.5795 185.987 36.2683 185.942 36.0905C185.631 34.8904 185.097 33.8681 184.252 33.1569C183.407 32.4012 182.34 32.179 181.006 32.4012C179.627 32.6679 178.737 33.2902 178.293 34.2681C177.848 35.246 177.715 36.4461 177.937 37.8241L178.07 38.5797L178.248 39.3354C178.56 40.7133 179.138 41.7801 179.894 42.5357C180.694 43.2914 181.762 43.5581 183.096 43.2914C184.43 43.1136 185.364 42.4913 185.853 41.4689Z" />
              <path d="M201.775 36.1794C201.153 35.6016 200.797 34.8904 200.752 34.0459C200.708 33.2013 200.975 32.4457 201.508 31.7789C202.087 31.1122 202.798 30.7566 203.643 30.7121C204.488 30.6677 205.244 30.9344 205.867 31.5122C206.489 32.0901 206.845 32.8013 206.89 33.6903C206.934 34.5348 206.667 35.2904 206.134 35.9127C205.555 36.5795 204.888 36.9351 204.043 36.9795C203.154 36.9795 202.398 36.7128 201.775 36.1794Z" />
              <path d="M219.119 42.3135C219.03 42.1801 218.941 42.0023 218.986 41.8246L220.098 22.6667C220.098 22.4889 220.187 22.3111 220.32 22.1778C220.454 22.0444 220.587 22 220.809 22L222.233 22.0889C222.41 22.0889 222.588 22.1778 222.677 22.3111C222.766 22.4445 222.855 22.6223 222.811 22.8001L221.788 40.1799L231.439 40.7578C231.661 40.7578 231.794 40.8467 231.928 40.98C232.061 41.1134 232.106 41.2912 232.106 41.469L232.017 42.5802C232.017 42.758 231.928 42.9358 231.794 43.0691C231.661 43.2025 231.483 43.2469 231.305 43.2469L219.564 42.5357C219.386 42.5357 219.208 42.4468 219.119 42.3135Z" />
              <path d="M235.397 42.2246C234.463 40.8467 234.107 39.1576 234.329 37.1573L234.507 35.9127L234.73 34.6681C235.13 32.6679 235.975 31.2011 237.309 30.1787C238.643 29.1564 240.333 28.8008 242.424 29.1119C244.469 29.4231 245.981 30.2676 246.96 31.6456C247.938 33.0235 248.294 34.7126 248.072 36.7128C248.027 37.1573 247.983 37.5574 247.938 37.9574C247.894 38.3575 247.805 38.7575 247.671 39.202C247.271 41.2022 246.426 42.6691 245.092 43.6914C243.758 44.7138 242.068 45.0694 239.977 44.7138C237.843 44.4915 236.331 43.6025 235.397 42.2246ZM243.402 41.9134C244.247 41.2467 244.781 40.1799 245.092 38.7131C245.136 38.5353 245.225 38.1352 245.314 37.6018C245.403 37.0684 245.448 36.7128 245.448 36.4906C245.626 35.0237 245.403 33.8236 244.825 32.9346C244.247 32.0456 243.313 31.5122 242.112 31.29C240.911 31.1122 239.844 31.3344 239.044 32.0012C238.199 32.6679 237.665 33.7347 237.353 35.2015L237.131 36.3128L236.998 37.424C236.82 38.8909 237.042 40.091 237.62 40.98C238.198 41.869 239.132 42.4024 240.333 42.6246C241.534 42.8024 242.557 42.5802 243.402 41.9134Z" />
              <path d="M251.407 46.3584C250.74 45.7806 250.251 45.1138 249.94 44.3137C249.628 43.5581 249.628 42.758 249.806 41.9579C250.118 40.7133 250.874 39.8243 252.119 39.2909C253.32 38.7575 254.832 38.5797 256.655 38.7575L261.014 39.2465L261.236 38.4019C261.725 36.4461 260.88 35.2015 258.656 34.6237C257.811 34.4015 257.055 34.4015 256.433 34.6237C255.81 34.846 255.321 35.1571 254.965 35.5571C254.832 35.7349 254.743 35.8238 254.609 35.8683C254.52 35.9127 254.387 35.9127 254.254 35.8683L253.142 35.6016C252.964 35.5571 252.83 35.4682 252.741 35.2904C252.653 35.1571 252.608 34.9793 252.653 34.8015C252.786 34.357 253.097 33.9125 253.631 33.4236C254.165 32.9346 254.921 32.6235 255.899 32.4012C256.833 32.179 257.945 32.2234 259.146 32.5346C261.236 33.068 262.615 33.957 263.282 35.1571C263.949 36.3572 264.082 37.7352 263.682 39.202L261.325 48.4475C261.28 48.6253 261.191 48.7587 261.014 48.892C260.88 48.9809 260.702 49.0254 260.524 48.9365L259.279 48.6253C259.101 48.5809 258.968 48.492 258.879 48.3142C258.79 48.1808 258.79 48.0031 258.834 47.7808L259.146 46.5362C258.568 47.0696 257.856 47.4252 256.966 47.6475C256.077 47.8697 255.01 47.8253 253.764 47.5141C252.875 47.3363 252.074 46.9363 251.407 46.3584ZM258.212 45.2027C259.19 44.5804 259.902 43.5136 260.302 41.9579L260.524 41.1578L257.1 40.8022C255.721 40.6689 254.609 40.7133 253.809 40.98C253.008 41.2467 252.519 41.7356 252.341 42.4024C252.163 43.158 252.297 43.8692 252.83 44.4471C253.364 45.0249 254.031 45.425 254.921 45.6472C256.121 45.9584 257.233 45.7806 258.212 45.2027Z" />
              <path d="M266.217 48.9365C265.728 47.3363 265.773 45.5583 266.395 43.647L266.662 42.8024L266.973 42.0023C267.73 40.1355 268.841 38.7575 270.265 37.8241C271.688 36.9351 273.333 36.8017 275.246 37.5129C277.113 38.1797 278.359 39.4243 278.892 41.1578L281.205 34.8015C281.294 34.6237 281.383 34.4904 281.561 34.4015C281.739 34.3126 281.872 34.3126 282.095 34.4015L283.34 34.8459C283.518 34.8904 283.651 35.0237 283.74 35.2015C283.829 35.3793 283.829 35.5571 283.74 35.7349L277.069 54.0482C276.98 54.226 276.891 54.3594 276.713 54.4483C276.535 54.5372 276.402 54.5372 276.18 54.4483L275.023 54.0038C274.845 53.9593 274.712 53.826 274.667 53.6482C274.623 53.4704 274.623 53.337 274.667 53.1148L275.112 51.9146C273.556 52.937 271.821 53.0703 269.864 52.3591C267.907 51.6924 266.707 50.5367 266.217 48.9365ZM274.445 50.1811C275.379 49.5588 276.091 48.6698 276.58 47.5141C276.669 47.3363 276.802 46.9807 276.98 46.4918C277.158 45.9584 277.291 45.6028 277.336 45.425C277.692 44.2693 277.692 43.2025 277.336 42.1357C276.98 41.0689 276.18 40.3133 274.934 39.8688C273.645 39.3798 272.533 39.4687 271.643 40.091C270.754 40.7133 270.042 41.6467 269.464 42.9802L269.153 43.7803C267.863 47.2919 268.53 49.5588 271.065 50.4922C272.355 50.8923 273.467 50.8034 274.445 50.1811Z" />
              <path d="M280.716 56.1818C280.671 56.004 280.671 55.8262 280.76 55.6484L286.453 42.9358C286.542 42.758 286.675 42.6247 286.809 42.5802C286.987 42.5358 287.164 42.5358 287.342 42.5802L288.499 43.1136C288.677 43.2025 288.81 43.3358 288.854 43.4692C288.899 43.6025 288.899 43.8248 288.81 44.0026L283.117 56.7152C283.028 56.893 282.895 57.0264 282.762 57.0708C282.584 57.1152 282.406 57.1152 282.228 57.0708L281.072 56.5374C280.894 56.493 280.76 56.3596 280.716 56.1818ZM287.876 39.5576C287.832 39.3798 287.832 39.202 287.92 39.0242L288.543 37.6463C288.632 37.4685 288.765 37.3351 288.899 37.2462C289.077 37.1573 289.255 37.1573 289.433 37.2462L290.989 37.9574C291.167 38.0463 291.301 38.1797 291.345 38.3575C291.434 38.5353 291.389 38.7131 291.345 38.8909L290.722 40.2688C290.633 40.4466 290.5 40.58 290.322 40.6244C290.144 40.6689 289.966 40.6689 289.788 40.58L288.232 39.8688C288.098 39.8688 287.965 39.7354 287.876 39.5576Z" />
              <path d="M286.72 58.8932C286.675 58.7154 286.675 58.5376 286.764 58.3598L293.346 46.0917C293.435 45.9139 293.569 45.825 293.746 45.7361C293.924 45.6917 294.102 45.6917 294.236 45.7806L295.347 46.3584C295.525 46.4473 295.659 46.5807 295.703 46.7585C295.748 46.9363 295.703 47.0696 295.614 47.2919L294.992 48.4476C295.837 48.092 296.682 47.9142 297.571 47.9142C298.416 47.9142 299.35 48.2253 300.373 48.7587C302.018 49.6477 302.997 50.8478 303.308 52.4036C303.62 53.9593 303.353 55.6039 302.419 57.3375L298.505 64.6717C298.416 64.8495 298.283 64.9384 298.105 65.0273C297.927 65.0717 297.749 65.0717 297.616 64.9828L296.459 64.3605C296.281 64.2716 296.192 64.1383 296.148 63.9605C296.104 63.7827 296.104 63.6049 296.192 63.4271L300.062 56.2707C300.729 55.0261 300.951 53.9149 300.729 52.8925C300.506 51.8702 299.839 51.0701 298.727 50.4922C297.66 49.9144 296.593 49.781 295.57 50.1366C294.547 50.4922 293.702 51.2923 293.035 52.4925L289.166 59.6489C289.077 59.8267 288.943 59.9156 288.765 60.0045C288.588 60.0489 288.41 60.0489 288.276 59.96L287.12 59.3377C286.898 59.1599 286.809 59.0266 286.72 58.8932Z" />
              <path d="M300.062 73.0727C299.573 72.0948 299.35 71.1613 299.439 70.3612C299.484 69.5611 299.661 68.9389 299.973 68.5388C300.062 68.4055 300.195 68.3166 300.418 68.2721C300.595 68.2277 300.773 68.2721 300.907 68.361L301.974 69.0722C302.108 69.1611 302.196 69.2945 302.241 69.4278C302.285 69.5611 302.285 69.7389 302.196 70.0056C301.752 71.6058 302.33 72.9393 303.931 74.0061C305.132 74.8062 306.199 75.1618 307.133 75.0285C308.067 74.8951 308.956 74.1839 309.846 72.8504L311.002 71.1613C309.268 71.7392 307.578 71.4725 305.888 70.3168C304.198 69.1611 303.308 67.7832 303.219 66.0941C303.13 64.405 303.62 62.7159 304.731 61.0268L305.176 60.3156L305.665 59.6933C306.822 58.0487 308.245 56.9819 309.801 56.404C311.403 55.8706 313.048 56.1818 314.738 57.293C316.428 58.4487 317.318 59.9156 317.406 61.8269L318.118 60.7601C318.207 60.5823 318.385 60.4934 318.563 60.449C318.741 60.4045 318.919 60.449 319.052 60.5379L320.075 61.2491C320.253 61.338 320.342 61.5158 320.342 61.6936C320.386 61.8714 320.342 62.0492 320.208 62.227L312.159 74.095C309.624 77.8288 306.51 78.4511 302.775 75.9175C301.44 75.0285 300.551 74.095 300.062 73.0727ZM310.824 69.3834C311.892 69.0278 312.781 68.361 313.537 67.3387C313.671 67.2053 313.849 66.9386 314.115 66.4941C314.382 66.0941 314.56 65.8274 314.649 65.6496C315.316 64.5828 315.583 63.4715 315.539 62.3603C315.45 61.2491 314.871 60.3156 313.76 59.56C312.603 58.7599 311.536 58.5821 310.513 58.9821C309.49 59.3822 308.556 60.1378 307.711 61.2491L307.266 61.8714L306.866 62.5381C306.11 63.7382 305.754 64.8939 305.799 65.9607C305.799 67.0275 306.421 67.961 307.578 68.7611C308.69 69.5167 309.757 69.7389 310.824 69.3834Z" />
              <path d="M325.901 76.762C325.856 75.9174 326.078 75.1618 326.657 74.4951C327.235 73.8283 327.946 73.4727 328.791 73.4283C329.636 73.3838 330.392 73.6061 331.059 74.1839C331.682 74.7618 332.038 75.4285 332.082 76.3175C332.127 77.162 331.86 77.9177 331.282 78.5844C330.748 79.2067 330.037 79.5623 329.147 79.6512C328.302 79.7401 327.546 79.4734 326.923 78.94C326.301 78.4066 325.945 77.6065 325.901 76.762Z" />
              <path d="M333.906 93.3417C333.906 93.1639 333.995 93.0306 334.128 92.8972L348.404 80.0513C348.538 79.9179 348.716 79.8735 348.893 79.8735C349.071 79.8735 349.205 79.9624 349.338 80.0957L350.317 81.1625C350.45 81.2959 350.495 81.4737 350.495 81.6515C350.495 81.8293 350.406 81.9626 350.272 82.096L337.33 93.7418L343.779 100.943C343.912 101.12 344.001 101.254 343.957 101.432C343.957 101.609 343.868 101.743 343.735 101.876L342.89 102.632C342.756 102.765 342.578 102.809 342.4 102.809C342.222 102.809 342.045 102.721 341.911 102.587L334.039 93.8751C333.95 93.6529 333.861 93.5195 333.906 93.3417Z" />
              <path d="M345.558 104.721C345.825 103.076 346.759 101.609 348.36 100.365L349.338 99.6091L350.361 98.8979C352.007 97.7422 353.697 97.2978 355.342 97.52C357.032 97.7423 358.456 98.6757 359.701 100.32C360.946 102.009 361.435 103.654 361.168 105.299C360.902 106.943 359.968 108.41 358.411 109.699C358.1 109.966 357.744 110.233 357.433 110.499C357.121 110.766 356.766 110.988 356.365 111.21C354.72 112.366 353.03 112.811 351.384 112.633C349.739 112.455 348.271 111.477 346.981 109.788C345.781 108.01 345.291 106.365 345.558 104.721ZM351.518 110.055C352.585 110.144 353.741 109.788 354.942 108.944C355.12 108.855 355.431 108.632 355.832 108.321C356.276 108.01 356.543 107.743 356.721 107.61C357.877 106.677 358.544 105.699 358.767 104.632C358.989 103.565 358.722 102.543 357.966 101.565C357.21 100.587 356.321 100.009 355.253 99.9203C354.186 99.8314 353.03 100.187 351.829 101.032L350.939 101.654L350.05 102.365C348.894 103.298 348.227 104.276 348.004 105.343C347.782 106.41 348.049 107.432 348.805 108.41C349.561 109.388 350.45 109.921 351.518 110.055Z" />
              <path d="M354.141 118.811C354.052 117.922 354.186 117.122 354.497 116.367C354.808 115.611 355.342 115.033 356.054 114.589C357.166 113.922 358.322 113.833 359.567 114.278C360.812 114.722 362.013 115.655 363.169 117.033L365.971 120.412L366.683 119.967C368.417 118.9 368.684 117.433 367.483 115.433C367.039 114.678 366.505 114.144 365.927 113.877C365.349 113.611 364.77 113.477 364.237 113.522C364.014 113.566 363.881 113.566 363.792 113.522C363.703 113.477 363.614 113.389 363.525 113.255L362.947 112.277C362.858 112.144 362.814 111.966 362.858 111.788C362.903 111.611 362.992 111.477 363.169 111.388C363.57 111.166 364.103 111.077 364.815 111.122C365.527 111.166 366.327 111.433 367.128 111.966C367.973 112.5 368.684 113.255 369.351 114.322C370.463 116.144 370.863 117.745 370.463 119.078C370.063 120.412 369.218 121.478 367.928 122.278L359.745 127.301C359.567 127.39 359.389 127.435 359.211 127.39C359.033 127.346 358.9 127.257 358.811 127.079L358.144 126.012C358.055 125.834 358.011 125.701 358.055 125.523C358.099 125.345 358.233 125.212 358.366 125.123L359.478 124.456C358.722 124.412 357.922 124.19 357.121 123.701C356.32 123.212 355.609 122.456 354.942 121.389C354.497 120.545 354.23 119.7 354.141 118.811ZM359.834 122.767C360.99 123.034 362.236 122.723 363.614 121.878L364.326 121.434L362.147 118.811C361.257 117.745 360.412 116.989 359.656 116.633C358.9 116.278 358.188 116.278 357.61 116.633C356.943 117.033 356.587 117.656 356.543 118.411C356.498 119.167 356.721 119.967 357.21 120.723C357.788 121.834 358.722 122.501 359.834 122.767Z" />
              <path d="M362.947 131.035C363.703 129.524 364.993 128.324 366.772 127.346L367.573 126.946L368.373 126.59C370.241 125.79 371.976 125.523 373.621 125.879C375.267 126.234 376.556 127.301 377.401 129.079C378.291 130.902 378.291 132.591 377.49 134.235L383.583 131.257C383.761 131.168 383.939 131.168 384.117 131.213C384.295 131.257 384.428 131.391 384.517 131.568L385.095 132.724C385.184 132.902 385.184 133.08 385.14 133.258C385.095 133.435 384.962 133.569 384.784 133.658L367.261 142.192C367.083 142.281 366.906 142.281 366.728 142.236C366.55 142.192 366.416 142.059 366.327 141.881L365.794 140.77C365.705 140.592 365.705 140.414 365.749 140.281C365.794 140.103 365.927 140.014 366.105 139.925L367.261 139.392C365.438 139.036 364.059 137.925 363.17 136.058C362.236 134.191 362.191 132.502 362.947 131.035ZM367.973 137.614C369.085 137.836 370.241 137.703 371.353 137.169C371.575 137.08 371.887 136.947 372.376 136.725C372.865 136.502 373.221 136.325 373.399 136.191C374.466 135.613 375.222 134.858 375.711 133.835C376.201 132.813 376.156 131.746 375.578 130.546C374.955 129.302 374.11 128.59 373.043 128.413C371.976 128.235 370.775 128.413 369.485 128.99L368.684 129.346C365.305 130.991 364.193 133.035 365.393 135.524C366.016 136.725 366.861 137.436 367.973 137.614Z" />
              <path d="M368.328 146.326C368.417 146.148 368.506 146.059 368.729 145.97L381.626 140.77C381.804 140.681 381.982 140.681 382.16 140.77C382.338 140.858 382.427 140.947 382.515 141.125L383.005 142.325C383.094 142.503 383.094 142.681 383.005 142.859C382.916 143.036 382.827 143.125 382.604 143.214L369.707 148.415C369.529 148.504 369.351 148.504 369.173 148.415C368.996 148.326 368.907 148.237 368.818 148.059L368.328 146.859C368.239 146.637 368.239 146.459 368.328 146.326ZM385.05 139.347C385.139 139.169 385.228 139.08 385.451 138.992L386.874 138.414C387.052 138.325 387.23 138.325 387.408 138.414C387.585 138.458 387.719 138.591 387.763 138.769L388.386 140.369C388.475 140.547 388.475 140.725 388.386 140.903C388.297 141.081 388.164 141.214 387.986 141.258L386.563 141.836C386.385 141.925 386.207 141.925 386.029 141.836C385.851 141.747 385.762 141.614 385.673 141.436L385.05 139.836C385.006 139.658 385.006 139.48 385.05 139.347Z" />
              <path d="M370.819 152.415C370.908 152.238 371.042 152.149 371.22 152.104L384.473 147.837C384.651 147.793 384.828 147.793 385.006 147.881C385.184 147.97 385.273 148.104 385.318 148.282L385.718 149.482C385.762 149.704 385.762 149.837 385.718 150.015C385.629 150.148 385.496 150.282 385.318 150.326L384.072 150.726C384.917 151.037 385.673 151.526 386.296 152.104C386.919 152.726 387.363 153.571 387.719 154.638C388.297 156.416 388.164 157.972 387.319 159.305C386.474 160.639 385.14 161.616 383.272 162.239L375.311 164.728C375.133 164.772 374.955 164.772 374.777 164.683C374.6 164.595 374.511 164.461 374.466 164.283L374.066 163.039C374.021 162.861 374.021 162.683 374.11 162.505C374.199 162.328 374.333 162.239 374.511 162.194L382.249 159.705C383.583 159.261 384.517 158.594 385.051 157.749C385.585 156.86 385.673 155.838 385.273 154.638C384.873 153.482 384.206 152.638 383.227 152.193C382.249 151.749 381.093 151.704 379.758 152.149L372.02 154.638C371.842 154.682 371.664 154.682 371.486 154.593C371.309 154.504 371.22 154.371 371.175 154.193L370.775 152.949C370.73 152.771 370.73 152.593 370.819 152.415Z" />
              <path d="M370.597 171.929C370.908 170.862 371.397 170.062 372.02 169.528C372.643 168.995 373.176 168.684 373.666 168.551C373.844 168.506 374.021 168.551 374.155 168.639C374.288 168.728 374.422 168.862 374.422 169.04L374.689 170.284C374.733 170.462 374.733 170.595 374.644 170.729C374.555 170.862 374.466 170.995 374.244 171.129C372.821 171.973 372.331 173.351 372.732 175.218C373.043 176.64 373.577 177.618 374.333 178.152C375.089 178.685 376.245 178.818 377.802 178.463L379.803 178.018C378.157 177.218 377.135 175.84 376.69 173.885C376.245 171.884 376.601 170.24 377.668 168.995C378.736 167.706 380.292 166.861 382.249 166.373L383.05 166.15L383.85 166.017C385.851 165.661 387.586 165.795 389.098 166.506C390.61 167.217 391.588 168.551 392.033 170.551C392.478 172.551 392.078 174.24 390.832 175.663L392.078 175.396C392.256 175.351 392.434 175.396 392.611 175.485C392.789 175.574 392.878 175.707 392.923 175.929L393.19 177.129C393.234 177.307 393.19 177.485 393.101 177.618C393.012 177.752 392.834 177.885 392.656 177.929L378.736 180.952C374.333 181.885 371.62 180.196 370.686 175.796C370.241 174.285 370.241 172.996 370.597 171.929ZM380.959 176.685C381.982 177.174 383.094 177.307 384.339 177.085C384.517 177.085 384.873 176.996 385.362 176.907C385.851 176.818 386.163 176.729 386.341 176.685C387.541 176.374 388.52 175.796 389.231 174.907C389.943 174.062 390.165 172.951 389.899 171.662C389.587 170.284 388.965 169.395 387.942 168.995C386.919 168.551 385.762 168.462 384.339 168.728L383.583 168.862L382.827 169.04C381.449 169.395 380.426 169.973 379.67 170.773C378.914 171.573 378.691 172.64 379.002 174.018C379.269 175.307 379.936 176.196 380.959 176.685Z" />
              <path d="M386.741 192.42C387.274 191.753 387.986 191.398 388.831 191.309C389.676 191.22 390.432 191.487 391.144 192.02C391.855 192.553 392.167 193.265 392.256 194.109C392.344 194.954 392.078 195.709 391.544 196.376C390.966 197.043 390.299 197.398 389.409 197.487C388.564 197.576 387.808 197.354 387.141 196.776C386.474 196.243 386.118 195.532 386.029 194.687C385.94 193.842 386.163 193.087 386.741 192.42Z" />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Loader;
